import loaderSignal from 'signals/Loader.signal';
import Loader from 'components/global/Loader';
import userSignal from 'signals/User.Signal';
import Header from './Header';
import Footer from './Footer';
import { ReactComponent as Logo } from '../../../images/logo-large.svg';

const ContentWrapper = ({ children, className }) => {
  const { isSignedIn } = userSignal.value;
  const { isContentLoading } = loaderSignal.value;

  if (isSignedIn) {
    return (
      <>
        <div
          className={
            isContentLoading
              ? 'min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1 bg-light-grey'
              : 'd-none'
          }
        >
          <Loader message="Loading..." className="text-center" />
        </div>
        <div
          className={
            isContentLoading
              ? 'd-none'
              : `container-content-md mt-lg-5 px-2 bg-light-gray-50 min-vh-100 position-relative ${
                  className || ''
                }`
          }
        >
          <Header />
          <div>{children}</div>
          <div className="position-absolute bottom-0 left-0 right-0 w-100">
            <Footer />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className={
          isContentLoading
            ? 'min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1 bg-light-grey'
            : 'd-none'
        }
      >
        <Loader message="Loading..." className="text-center" />
      </div>
      <div
        className={
          isContentLoading
            ? 'd-none'
            : `container-content-md d-flex mt-lg-5 px-2 bg-light-gray-50 min-vh-100 position-relative public-route justify-content-center align-items-center ${
                className || ''
              }`
        }
      >
        <Logo className="public-route-logo" />
        <div className="pt-80 pb-104">{children}</div>
        <footer className="position-absolute d-flex bg-primary-200 footer bottom-0 left-0 right-0 w-100 justify-content-center align-items-center">
          <p className="base text-light">
            By signing up you agree to FedComp's{' '}
            <a
              style={{ color: 'white' }}
              target="_blank"
              href={'https://iqneo.app:8443/IQNRDocs/IQNEODisclaimer.pdf'}
            >
              Terms of Service
            </a>{' '}
            and our{' '}
            <a
              style={{ color: 'white' }}
              target="_blank"
              href={'https://www.iubenda.com/privacy-policy/69947298'}
            >
              Privacy Policy
            </a>
          </p>
        </footer>
      </div>
    </>
  );
};

export default ContentWrapper;
