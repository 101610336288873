import { COLORS } from "utils/constants";
import { ReactComponent as SettingsIcon } from "../../../../images/settings.svg";
import { Overlay } from "react-bootstrap";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { logout } from "api/auth";

const SettingsDropdown = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const iconRef = useRef(null);

  const handleClick = () => {
    history.push("/settings");
    setShow(false);
  };

  const handleLogout = () => {
    logout();
    history.push('/login');
  };
  return (
    <>
      <div ref={iconRef} onClick={() => setShow(true)}>
        <SettingsIcon
          fill={COLORS.info}
          width={24}
          className="pointer"
        />
      </div>
      <Overlay
        offset={[0, 8]}
        rootClose
        transition={false}
        onHide={() => setShow(false)}
        target={iconRef.current}
        show={show}
        placement="bottom-end"
      >
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            {...props}
            className="bg-secondary-500 overflow-hidden position-absolute rounded settings-dropdown z-50"
            style={{ ...props.style }}
          >
            <div
              className="nav-hover pointer"
              onClick={handleClick}
            >
              <p className="p-8 base fw-semi-bold">Settings</p>
            </div>
            <div className="divider-horizontal" />
            <div
              className="nav-hover pointer"
              onClick={handleLogout}
            >
              <p className="p-8 base fw-semi-bold">Sign Out</p>
            </div>
          </div>
        )}
      </Overlay>
    </>
  );
};

export default SettingsDropdown;
