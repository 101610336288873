import { cloneElement } from "react";

const NavMenuButton = ({ children, icon, onClick = null }) => (
  <div
    className="py-16 px-8 d-flex flex-row nav-hover pointer"
    onClick={onClick}
  >
    {icon &&
      cloneElement(icon, {
        className: "me-16",
      })}
    <p className="base fw-semi-bold">{children}</p>
  </div>
);

export default NavMenuButton;
