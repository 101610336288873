
export const getConfig = (key) => {
  const base = {
    BASE_URL: 'http://localhost:3000',
    REACT_APP_FIREBASE_PUB_KEY: 'AIzaSyAVqaZixANtj-YPGkZHmuUx8Hwbi4rKm6o',
    REACT_APP_FIREBASE_AUTH_DOMAIN: 'fyc-sample.firebaseapp.com',
    REACT_APP_FIREBASE_DATABASE_URL: 'https://fyc-sample.firebaseio.co',
    REACT_APP_FIREBASE_PROJECT_ID: 'fyc-sample',
    REACT_APP_FIREBASE_STORAGE_BUCKET: 'fyc-sample.appspot.com',
    REACT_APP_FIREBASE_MESSAGING_SENDER_ID: '493811103947',
    REACT_APP_FIREBASE_APP_ID: '1:493811103947:web:2edf29ba9954b8d025834e',
    REACT_APP_API_ENDPOINT: 'http://localhost:5001',
  };

  const development = {
    ...base,
  };

  const feature = {
    ...development,
    BASE_URL: 'http://localhost:3000',
    REACT_APP_API_ENDPOINT: 'http://localhost:5001',
  };

  const qa = {
    ...development,
    BASE_URL: 'http://localhost:3000',
    REACT_APP_API_ENDPOINT: 'http://localhost:5001',
  };

  const production = {
    ...qa,
    BASE_URL: 'http://localhost:3000',
    REACT_APP_API_ENDPOINT: 'http://localhost:5001',
  };

  const configSettings = {
    development,
    feature,
    qa,
    production,
  };

  const env = process.env.REACT_APP_ENV || 'production';

  const config = configSettings[env] || configSettings.development;

  if (!key) {
    return config;
  }

  return config[key];
};

export default {
  getConfig,
};
