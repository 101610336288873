import auth from "api/auth";
import Loader from "components/global/Loader";
import { useCallback, useEffect, useState } from "react";

const AuthWrapper = ({ children }) => {
  const [appIsLoading, setAppIsLoading] = useState(true);

  const init = useCallback(async () => {
    await auth.fetchAndSetUserOnPageRefresh();
    setAppIsLoading(false);
  }, []);

  useEffect(() => {
    init();
  }, [])

  if (appIsLoading) {
    return (
      <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1 text-center">
        <Loader message="Loading..." />
      </div>
    );
  }

  return <div>{children}</div>;
};

export default AuthWrapper;