const NavBarButton = ({ children, name, activeKey, onClick }) => (
  <div className="px-16 py-8 pointer">
    <p
      className={`px-16 base fw-bold ${
        activeKey === name ? "text-info" : "text-light"
      }`}
      onClick={onClick}
    >
      {children}
    </p>
  </div>
);

export default NavBarButton;
