// functions.js

import { enc, HmacSHA256, AES, mode, pad } from 'crypto-js';

export const formatCurrency = value => {
  if (typeof value === 'number') {
    value = value.toFixed(2);
  } else if (typeof value !== 'string') {
    value = value.toString();
  }

  value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return `$${value}`;
};

export const extractNumber = value => {
  if (typeof value === 'number') {
    return value; // If it's already a number, return it as is.
  }

  const numericValue = value.replace(/[^\d.]/g, '');
  const parsedNumber = parseFloat(numericValue);

  return isNaN(parsedNumber) ? 0 : parsedNumber;
};

export const formatString = input => {
  const words = input.split('_');

  const formattedString = words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return formattedString;
};

export const hashString = async ({ secret, value }) => {
  try {
    const hmacEncodedStr = HmacSHA256(value, secret).toString(enc.Hex);

    return hmacEncodedStr;
  } catch (error) {
    console.warn(error);
  }
};

export const encryptString = (value, session) => {
  const key = enc.Utf8.parse(session.slice(3, 19));

  const encrypted = AES.encrypt(value, key, {
    mode: mode.ECB,
    padding: pad.Pkcs7,
  });

  return encrypted.toString();
};

export const validateEmail = text => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (!emailPattern.test(text)) {
    throw new Error('Please enter a valid email');
  }
};

export const validatePassword = text => {
  const passwordPattern =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])(?!.*\s).{8,}$/;
  if (!passwordPattern.test(text)) {
    throw new Error(
      'Your password must be between 8-30 characters, contain 1 upper case, one number and one special character and no blank spaces.'
    );
  }
};

export const validateRequiredFields = fields => {
  for (const key in fields) {
    if (!fields[key]) {
      throw new Error(`The ${key} field is required.`);
    }
  }
};

export const validateDate = date => {
  const [month, day, year] = date.split('/');
  const currentDate = new Date();
  currentDate.setUTCHours(0, 0, 0, 0);
  const givenDate = new Date(`${year}-${month}-${day}`);
  if (currentDate > givenDate) {
    throw new Error(
      `The selected date - ${date} - cannot be before the present date.`
    );
  }
};

export const validateFieldsAreNotEqual = (obj, field1, field2) => {
  if (obj[field1] === obj[field2]) {
    throw new Error(`${field1} cannot be the same as ${field2}`);
  }
};

export const validatePasswordsAreNotEqual = (currentPassword, newPassword) => {
  if (currentPassword === newPassword) {
    throw new Error(`Old Password cannot be the same as New Password`);
  }
};

export const validateFieldsAreEqual = fields => {
  const fieldKeys = Object.keys(fields);

  if (fields[fieldKeys[0]] !== fields[fieldKeys[1]]) {
    throw new Error(`${fieldKeys[0]} must match ${fieldKeys[1]}`);
  }
};

export const capitalizeFirst = mySentence => {
  const finalSentence = mySentence
    .toLowerCase()
    .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  return finalSentence;
};
