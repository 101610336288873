/* eslint-disable react/jsx-props-no-spreading */
import { Route, Redirect } from "react-router-dom";
import userSignal from "signals/User.Signal";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isSignedIn } = userSignal.value;

  return (
    <Route
      {...rest}
      render={(props) =>
        isSignedIn ? (
          <div className="px-lg-80 pb-104">
            <Component {...props} />
          </div>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
