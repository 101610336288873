import { Button, Col, Collapse, Row } from 'react-bootstrap';
import Logo from '../../../images/logo.svg';
import HeaderBackground from '../../../images/header-background.png';
import { ReactComponent as Bars } from '../../../images/bars.svg';
import { COLORS } from 'utils/constants';
import { useEffect, useRef, useState } from 'react';
import NavMenuButton from './components/NavMenuButton';
import { ReactComponent as DashboardIcon } from '../../../images/credit-card-color.svg';
import { ReactComponent as LoansIcon } from '../../../images/money-bag-color.svg';
import { ReactComponent as TransfersIcon } from '../../../images/transfers.svg';
import { ReactComponent as ApplyIcon } from '../../../images/loan-apply-color.svg';
import { ReactComponent as FaqIcon } from '../../../images/faq-icon.svg';
import { ReactComponent as BillPayIcon } from '../../../images/bill-pay-color.svg';
import { ReactComponent as StatementsIcon } from '../../../images/statements-color.svg';
import { ReactComponent as SettingsIconColor } from '../../../images/settings-color.svg';
import { ReactComponent as ContactUsIcon } from '../../../images/contact-us-color.svg';
import { ReactComponent as LoanCalculator } from '../../../images/calculator-icon.svg';
import SettingsDropdown from './components/SettingsDropdown';
import { useHistory } from 'react-router-dom';
import NavBarButton from './components/NavBarButton';
import userSignal from 'signals/User.Signal';
import NotificationsDropdown from './components/NotificationsDropdown';

const OPTIONS = { year: 'numeric', month: 'long', day: 'numeric' };
const Header = () => {
  const [displayName, setDisplayName] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [menuWidth, setMenuWidth] = useState('auto');
  const history = useHistory();
  const menuParentRef = useRef(null);
  const activeKey = history.location?.pathname?.split('/')?.[1];

  const user = userSignal.value;

  useEffect(() => {
    const init = async () => {
      setDisplayName(
        user?.MemberAcct?.DisplayName || user?.MemberAcct?.FirstName
      );
      const { CUInfo } = user;
    };
    init();
  }, [user?.MemberAcct?.DisplayName, user?.MemberAcct?.FirstName]);
  const renderGreeting = () => {
    const hour = new Date().getHours();
    let greeting = `Good ${
      hour < 12 ? 'morning' : hour < 18 ? 'afternoon' : 'evening'
    }`;
    if (displayName) {
      greeting += `, ${displayName}`;
    }
    return greeting;
  };
  useEffect(() => {
    setMenuWidth(menuParentRef.current.clientWidth);
  }, [menuParentRef.current && menuParentRef.current.clientWidth]);

  useEffect(() => {
    const handleClickOutside = event => {
      // toggle header menu if clicked outside
      if (!event.target.closest('.header-menu, .header-toggle')) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMenuClick = route => {
    setShowMenu(false);
    history.push(route);
  };

  return (
    <>
      <div className="w-100 bg-primary position-relative header">
        <img src={HeaderBackground} className="img img-fluid w-100 h-100" />
        <div className="h-100 w-100 pe-80 d-flex flex-row align-items-center justify-content-between position-absolute left-0 top-0">
          <div ref={menuParentRef} className="h-100 pe-16">
            <div className="d-flex flex-row h-100 align-items-center">
              <div
                onClick={() => setShowMenu(!showMenu)}
                className={`d-flex h-100 px-32 align-items-center pointer z-51 header-toggle ${
                  showMenu ? 'bg-secondary-500' : 'bg-transparent'
                }`}
              >
                <Bars
                  fill={showMenu ? COLORS.gray : COLORS.info}
                  width={16}
                  height={16}
                />
              </div>
              <div
                className={`${
                  showMenu ? 'border-transparent' : 'border-secondary'
                } divider-vertical my-8 me-32 h-75`}
              />
              <img
                className="img img-fluid me-56 pointer"
                src={Logo}
                width={48}
                onClick={() => history.push('/dashboard')}
              />
              <div className="d-flex flex-row">
                <NavBarButton
                  onClick={() => history.push('/dashboard')}
                  activeKey={activeKey}
                  name="dashboard"
                >
                  Dashboard
                </NavBarButton>
                {/* <NavBarButton
                  onClick={() => history.push("/requestvideochat")}
                  activeKey={activeKey}
                  name="requestvideochat"
                >
                  VideoChat
                </NavBarButton> */}
                {/* <NavBarButton
                  onClick={() => history.push("/deposits")}
                  activeKey={activeKey}
                  name="deposits"
                >
                  Deposits
                </NavBarButton> */}
                <NavBarButton
                  onClick={() => history.push('/bill-pay')}
                  activeKey={activeKey}
                  name="bill-pay"
                >
                  Bill Pay
                </NavBarButton>
                <NavBarButton
                  onClick={() => history.push('/loans')}
                  activeKey={activeKey}
                  name="loans"
                >
                  Loans
                </NavBarButton>
                <NavBarButton
                  onClick={() => history.push('/transfers')}
                  activeKey={activeKey}
                  name="transfers"
                >
                  Transfers
                </NavBarButton>
                <NavBarButton
                  onClick={() => history.push('/faq')}
                  activeKey={activeKey}
                  name="faq"
                >
                  FAQ
                </NavBarButton>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center">
            <span style={{ padding: 3, marginRight: 53, color: 'white' }}>
              <div style={{ color: 'white' }}>{renderGreeting()}</div>

              <div style={{ color: 'white', textAlign: 'center' }}>
                {new Date().toLocaleDateString('en-US', OPTIONS)}
              </div>
            </span>
            <Button
              className="bg-light text-primary fw-bold me-32"
              onClick={() => history.push('/contact-us')}
            >
              Contact Us
            </Button>
            {/* <div className="me-32">
              <NotificationsDropdown />
            </div> */}
            <SettingsDropdown />
          </div>
        </div>
      </div>
      <Collapse in={showMenu} className="header-collapse">
        <div
          className="bg-secondary-500 header-menu-shadow border-bottom-right-radius position-absolute z-50 header-menu"
          style={{
            width: menuWidth,
          }}
        >
          <Row className="px-24 py-8">
            <Col>
              <NavMenuButton
                icon={<DashboardIcon width={24} height={24} />}
                onClick={() => handleMenuClick('/dashboard')}
              >
                Dashboard
              </NavMenuButton>
              <NavMenuButton
                icon={<LoansIcon width={24} height={24} />}
                onClick={() => handleMenuClick('/loans')}
              >
                Loans
              </NavMenuButton>
              <NavMenuButton
                icon={<TransfersIcon width={24} height={24} />}
                onClick={() => handleMenuClick('/transfers')}
              >
                Tranfers
              </NavMenuButton>
            </Col>
            <Col>
              {/* <NavMenuButton
                icon={<RequestVideoChatIcon width={24} height={24} />}
                onClick={() => handleMenuClick("/requestvideochat")}
              >
                RequestVideoChat
              </NavMenuButton> */}
              <NavMenuButton
                icon={<BillPayIcon width={24} height={24} />}
                onClick={handleMenuClick}
              >
                Bill Pay
              </NavMenuButton>
              <NavMenuButton
                icon={<StatementsIcon width={24} height={24} />}
                onClick={() => handleMenuClick('/statements')}
              >
                Statements
              </NavMenuButton>
              <NavMenuButton
                icon={<ApplyIcon width={24} height={24} />}
                onClick={() => handleMenuClick('/apply')}
              >
                Apply
              </NavMenuButton>
            </Col>
            <Col>
              <NavMenuButton
                icon={<SettingsIconColor width={24} height={24} />}
                onClick={() => handleMenuClick('/settings')}
              >
                Settings
              </NavMenuButton>
              <NavMenuButton
                icon={<ContactUsIcon width={24} height={24} />}
                onClick={() => handleMenuClick('/contact-us')}
              >
                Contact Us
              </NavMenuButton>
              <NavMenuButton
                icon={<LoanCalculator width={24} height={24} />}
                onClick={() => handleMenuClick('/loan-calculator')}
              >
                Calculator
              </NavMenuButton>
              <NavMenuButton
                icon={<FaqIcon width={24} height={24} />}
                onClick={() => handleMenuClick('/faq')}
              >
                FAQ
              </NavMenuButton>
            </Col>
          </Row>
        </div>
      </Collapse>
    </>
  );
};

export default Header;
