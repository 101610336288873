/* eslint-disable no-alert */
/* eslint-disable no-console */
import { Button, Col, Row } from 'react-bootstrap';
import alertSignal from 'signals/Alert.signal';
import { ReactComponent as XMark } from '../../../images/x-mark.svg';
import { COLORS } from 'utils/constants';

const Notification = ({ alert, onCloseClick }) => (
  <div style={{ zIndex: 2000 }} className="container-fluid fixed-top">
    <Row
      className={`shadow-sm border-bottom border-secondary-300 border-2 py-24 align-items-center ${
        alert.className || 'bg-light'
      }`}
    >
      <Col sm="4" className="offset-4 text-center base text-primary-200">
        {alert.message}
      </Col>
      <Col sm="4" className="text-right">
        <div className="d-flex justify-content-end">
          <XMark className="pointer" width={16} height={16} fill={COLORS['primary-200']} onClick={onCloseClick} />
        </div>
      </Col>
    </Row>
  </div>
);

const Modal = ({ alert, onCloseClick, overrideCloseMessage }) => (
  <div
    className="modal fade show modal-background"
    style={{ display: 'block' }}
    role="dialog"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content border-dark card-shadow">
        <div className="modal-header bg-warning text-white lead">
          An Error has occured!
        </div>
        <div className="modal-body base">{alert.message}</div>
        <div className="modal-footer">
          <Button className="btn btn-dark" onClick={onCloseClick} type="button">
            {overrideCloseMessage || 'Close'}
          </Button>
        </div>
      </div>
    </div>
  </div>
);

const Alert = () => {
  const alert = alertSignal.value;

  if (alert.error) {
    console.error(alert.error);
  }

  const handleCloseClick = () => {
    if (alert.onClose) {
      alert.onClose();
    }
    alertSignal.reset();
  };

  if (!alert.type) {
    return null;
  }

  if (alert.type === 'notification') {
    setTimeout(() => {
      alertSignal.reset();
    }, 5000);
    return <Notification alert={alert} onCloseClick={handleCloseClick} />;
  }

  if (alert.type === 'alert') {
    return (
      <Modal
        alert={alert}
        onCloseClick={handleCloseClick}
        overrideCloseMessage={alert.overrideCloseMessage}
      />
    );
  }

  if (alert.type === 'critical') {
    window.alert(alert.message);
    alertSignal.reset();
    return null;
  }

  if (alert.type === 'passive') {
    return null;
  }

  return <div />;
};

export default Alert;
