/* eslint-disable global-require */
import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./scss/style.scss";
import Loader from "components/global/Loader";
import PublicRoute from "components/global/Routes/PublicRoute";
import PrivateRoute from "components/global/Routes/PrivateRoute";
import ContentWrapper from "components/global/ContentWrapper";
import Alert from "components/global/Alert/Alert";
import { Chart as ChartJS, ArcElement, Legend, Tooltip } from "chart.js";
import { useHistory } from "react-router-dom";
import userSignal from "signals/User.Signal";
import AuthWrapper from "components/AuthWrapper";
// import { initializeApp } from "firebase/app";

const routes = require("./routes.json");

ChartJS.register(
  // need this for charts to render
  ArcElement,
  Tooltip,
  Legend
);

const LoadingSuspense = () => (
  <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1">
    <Loader message="Loading..." className="text-center" />
  </div>
);

const UserRoutes = () => {
  const { isSignedIn } = userSignal.value;
  const history = useHistory();
  const currentPath = history.location.pathname;

  return (
    <Switch>
      <ContentWrapper>
        <Alert />
        <Suspense fallback={<LoadingSuspense />}>
          {routes.map((route) => {
            const component = React.lazy(() => import(`./${route.viewPath}`));

            if (route.router === "private") {
              return (
                <PrivateRoute
                  exact
                  path={route.urlPath}
                  component={component}
                  key={route.viewPath}
                />
              );
            }
            if (route.router === "public") {
              return (
                <PublicRoute
                  exact
                  path={route.urlPath}
                  component={component}
                  key={route.viewPath}
                />
              );
            }
            return null;
          })}

          {/* Only redirect if the currentPath is not in routes map */}
          {!routes.some((route) => route.urlPath === currentPath) && (
            <Redirect to={isSignedIn ? "/dashboard" : "/login"} />
          )}
        </Suspense>
      </ContentWrapper>
    </Switch>
  );
};

const App = () => {
  // const firebaseConfig = {
  //   apiKey: "AIzaSyCsVYL9RHKOpa2OUO1AfVGeJVxMtPPQiLk",
  //   authDomain: "first-dev-iqnr-site.firebaseapp.com",
  //   projectId: "first-dev-iqnr-site",
  //   storageBucket: "first-dev-iqnr-site.appspot.com",
  //   messagingSenderId: "613573563639",
  //   appId: "1:613573563639:web:f921cc85f1b819fc9b2ff2",
  // };
  // const app = initializeApp(firebaseConfig);
  return (
  <div className="position-relative">
    <AuthWrapper>
      <Router>
        <Route component={UserRoutes} />
      </Router>
    </AuthWrapper>
  </div>
);
}
export default App;
