import { COLORS } from "utils/constants";
import { ReactComponent as NotificationsIcon } from "../../../../images/bell.svg";
import { Overlay } from "react-bootstrap";
import { useRef, useState } from "react";
import notificationsSignal from "signals/Notifications.signal";

const Subheader = ({ children }) => (
  <p className="bg-secondary-500 px-24 small fw-bold py-4">{children}</p>
);

const Notification = ({ children }) => (
  <p className="base py-16 px-24">{children}</p>
);

const NotificationsDropdown = () => {
  const [show, setShow] = useState(false);
  const iconRef = useRef(null);
  const { notifications } = notificationsSignal.value;

  return (
    <>
      <div ref={iconRef} onClick={() => setShow(true)}>
        <NotificationsIcon fill={COLORS.info} width={24} className="pointer" />
      </div>
      <Overlay
        offset={[0, 8]}
        rootClose
        transition={false}
        onHide={() => setShow(false)}
        target={iconRef.current}
        show={show}
        placement="bottom-end"
      >
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            {...props}
            className="bg-light overflow-hidden position-absolute rounded notifications-dropdown z-50"
            style={{ ...props.style }}
          >
            <div className="d-flex flex-row justify-content-between align-items-center py-8 ps-24 pe-8 bg-secondary-400">
              <p className="base fw-bold text-primary">Notifications</p>
              <p className="small pointer">Mark all as read</p>
            </div>
            {Object.keys(notifications)?.map((notificationKey) => (
              <div key={notificationKey}>
                <Subheader>{notificationKey}</Subheader>
                {notifications[notificationKey].map((notification, index) => (
                  <div key={notification.id} className={index < notifications[notificationKey].length - 1 ? "divider-horizontal" : ""}>
                    <Notification>
                      {notification.message}
                    </Notification>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </Overlay>
    </>
  );
};

export default NotificationsDropdown;
