import { Spinner } from "react-bootstrap";
import loaderSignal from "signals/Loader.signal";
import { ReactComponent as Logo } from '../../../images/logo-large-light.svg';

const Loader = ({ message = "", className }) => {
  const { isSigningOut } = loaderSignal.value;
  
  if (isSigningOut) {
    return (
      <div className={className}>
        <Logo className="sign-out-logo" />
        <h4 className="text-primary my-112">You are being securely signed out.</h4>
        <Spinner variant="primary" />
      </div>
    );
  }

  return (
    <div className={className}>
      <Spinner variant="primary" />
      {message && <h4 className="mt-32 text-primary">{message}</h4>}
    </div>
  );
};

export default Loader;
