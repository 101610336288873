export const COLORS = {
  primary: '#07278A',
  'primary-200': '#2C479C', 
  light: '#FFFFFF',
  secondary: '#00DCF5',
  'secondary-500': '#F2FAFF',
  'secondary-400': '#C7E9FF',
  'secondary-300': '#8BC9EF',
  'secondary-200': '#99F4FF',
  info: '#FCB701',
  warning: '#DC3545',
  success: '#165322',
  dark: '#101010',
  gray: '#545454',
  'light-gray': '#BFBFBF',
  'light-gray-50': '#E7E7E7',
  'light-gray-25': '#F2F2F2',
  light: '#FFFFFF',
};

export const RECURRING_REQUESTVIDEOCHAT_FREQUENCY_LABELS = [
  { label: 'Weekly', value: 'Weekly' },
  { label: 'Bi-weekly', value: 'Bi-weekly' },
  { label: 'Monthly', value: 'Monthly' },
  { label: 'Annually', value: 'Annually' },
];

export const generalDoughnutChartOptions = {
  plugins: {
    legend: {
      display: false, // Hide the legend
    },
    datalabels: {
      display: false,
    },
    tooltip: {
      enabled: false, // Disable tooltips
    },
  },
  cutout: '75%',
  hover: {
    mode: null, // Set the interaction mode to 'nearest' to disable hover effects
  },
  animation: false,
};

export const LOAN_TYPES = {
  car: 'NEW VEHICLE',
  home: 'R/E HM EQUITY <12 YR',
  money: 'money',
};

export const APPLY_FOR_LOAN_VIEW_STATE = {
  loanType: 'loanType',
  loanTypeDetail: 'loanTypeDetail',
  enterInformation: 'enterInformation',
  reviewInformation: 'reviewInformation',
};

export const EDIT_SETTINGS_STATE = {
  enterInformation: 'enterInformation',
  reviewInformation: 'reviewInformation',
};
