const BASE_URL = 'https://iqneo.app:8443/';

const METHODS_WITH_BODY = ['PUT', 'POST', 'PATCH'];
const globalHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};
class Fetcher {
  static instance;

  constructor({ headers = {}, log = false } = {}) {
    // NOTE:
    // UNCOMMENT THE FOLLOWING IF YOU WANT A SINGLETON PATTERN
    // AND ALWAYS HAVE THE SAME CLASS WITH THE SAME CONFIGURATION
    // NO MATTER WHAT PARAMETERS YOU SEND IN

    // if (Fetcher.instance) {
    //   return Fetcher.instance;
    // }

    this.baseUrl = BASE_URL;
    this.headers = headers;
    this.log = log;
    Fetcher.instance = this;
  }

  async fetcher({ method, path, body, headers = {} } = {}) {
    const isMethodWithBody = METHODS_WITH_BODY.includes(method);

    if (isMethodWithBody && !body) {
      throw new Error('Request body is required');
    }

    const reqBody = {
      method,
      headers: {
        ...globalHeaders,
        ...this.headers,
        ...headers,
      },
    };

    if (isMethodWithBody) {
      reqBody.body = JSON.stringify(body);
    }

    if (this.log) {
      console.log({
        method,
        path,
        body,
        headers: {
          ...globalHeaders,
          ...this.headers,
          ...headers,
        },
        baseurl: this.baseUrl,
        requestUrl: `${this.baseUrl}${path}`,
      });
    }

    const req = await fetch(`${this.baseUrl}${path}`, reqBody);

    const data = await req.json();

    return data;
  }

  async get({ path = '', headers = {} }) {
    const req = await this.fetcher({
      method: 'GET',
      path,
      headers,
    });

    if (req?.IQResult?.Status === 'Error') {
      console.warn(req);
      throw new Error(req.IQResult.StatusDescription);
    }

    return req;
  }

  async delete({ path = '', headers = {} }) {
    const req = await this.fetcher({
      method: 'DELETE',
      path,
      headers,
    });

    return req;
  }

  async post({ body, path = '', headers = {} }) {
    const req = await this.fetcher({
      method: 'POST',
      path,
      body,
      headers,
    });

    if (req?.IQResult?.Status === 'Error') {
      console.warn(req);
      throw new Error(req.IQResult.StatusDescription);
    }

    return req;
  }

  async put({ body, path = '', headers = {} }) {
    const req = await this.fetcher({
      method: 'PUT',
      path,
      body,
      headers,
    });

    return req;
  }

  async patch({ body, path = '', headers = {} }) {
    const req = await this.fetcher({
      method: 'PATCH',
      path,
      body,
      headers,
    });

    console.log({ req });
    return req;
  }
}

module.exports = Fetcher;
