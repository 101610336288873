import FooterBackground from '../../../images/footer.png';

const Footer = () => (
  <div className="w-100 position-relative footer">
    <img src={FooterBackground} className="img img-fluid w-100 h-100" />
    <p
      className="text-light small fw-bold me-lg-80 position-absolute top-50 end-0"
    >
      © 2023 FedComp, All rights reserved.
    </p>
  </div>
);

export default Footer;
